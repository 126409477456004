import React, { useState } from "react"
import styled from "@emotion/styled"

import styles from "./menu.module.css"
import MenuDrawer from "./menu-drawer"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook, faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons"
import { faBars, faWindowClose, faCaretRight } from "@fortawesome/free-solid-svg-icons"

function showOverlay() {
  document.body.classList.add('modal-open');
}

function hideOverlay() {
  document.body.classList.remove('modal-open');
}

const executeScroll = (ref, setOverlayState) => {
  hideOverlay()
  setOverlayState(false)
  ref.current.scrollIntoView({behavior: "smooth"})   
}

const MenuBar = styled.header`
  position: absolute;
  display: flex;
  marginBottom: 1.45rem;
  width: 100%;
  z-index: 998;
  font-size: 16px;
  text-shadow: 4px 4px 4px #00000069;
  @media (min-width: 1024px) {
    display: flex;
  }
`
const FixedLeft = styled.div`
  flex: 1;
  z-index:1;
`
const FixedRight = styled.div`
  position: absolute;
  z-index:1;
  right: 0;
  flex: 1;
  text-align: right;
`
const MenuBarIcon = styled.div`
  padding: 16px;
  font-size: 16px;
  @media (min-width: 480px) {
    padding: 16px;
    font-size: 16px;
  }
  @media (min-width: 768px) {
    padding: 28px;
    font-size: 24px;
  }
`
const FlexContainer = styled.div`
	height: 100vh;
	padding: 0;
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
`
const Row = styled.div`
  width: auto;
`
const FlexItem = styled.div`
  padding: 10px;
  margin: 10px;
  text-align: center;
`
const Logo = styled.div`
  padding: 0px;
  a {
    padding: 0;
  }
  @media (min-width: 480px) {
  }
  @media (min-width: 768px) {
  }
`
const ButtonMobileDrawer = styled.div`
  display: flex;
  flex-flow: nowrap row;
  align-items: center;
  border: 0;
  background-color: transparent;
  &:hover {
    cursor:pointer;
  }
  .gatsby-image-wrapper {
    width: 120px;
  }
`
const OverlaySocialIcons = styled.div`
  position: absolute;
  bottom: 20px;
	left: 20px;
  text-align: center;
`
const OverlayLangSelector = styled.div`
  position: absolute;
  bottom: 20px;
	right: 20px;
  text-align: right;
`
const SocialIcon = styled.a`
  font-size: 16px;
  color: #00ABD4;
  padding: 6px;
  @media (min-width: 768px) {
    padding: 0 4px;
  }
`
const PageLinkButton = styled.button`
  font-size: 18px;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  font-family: 'Oswald';
  color: #fff;
  padding: 0 10px 4px 10px;
  margin-right: 10px;
  text-shadow: #00000069 4px 4px 4px;
  &:hover {
    border-bottom: 2px solid #06abd4;
  }
`
const Mobile = ({
  lang,
  openState,
  setOpenState,
  refs,
  page_links,
  social_links,
  image
}) => {

  const [overlayState, setOverlayState] = useState(false)
  const dynamicStyle = overlayState ?
    styles.overlay : styles.overlayHidden

  const path = lang.includes('en') ? '/' : '/en/'
  const pathLabel = lang.includes('en') ? 'FR' : 'EN'

  return (
    <MenuBar>
      <MenuDrawer
        lang={lang}
        width="90vw"
        openState={openState}
        setOpenState={setOpenState}
        closeButton
      />
      { overlayState &&
        <div className={styles.overlay}>
          <FixedRight>
            <MenuBarIcon
              onClick={()=> {
                setOverlayState(false)
                hideOverlay()
              }}>
              <FontAwesomeIcon icon={faWindowClose}/>
            </MenuBarIcon>
          </FixedRight>
          <FlexContainer>
            <Row> 
              { page_links.map((e) => (
                <FlexItem>
                  <PageLinkButton onClick={()=>executeScroll(e.ref, setOverlayState)}>
                    { e.name }
                  </PageLinkButton>
                </FlexItem>
              ))}
            </Row>
          </FlexContainer>
          <OverlaySocialIcons>
            { social_links.map((e) => (
                <SocialIcon key={e.href} href={e.href}>
                  <FontAwesomeIcon icon={e.icon}/>
                </SocialIcon>
            ))}
          </OverlaySocialIcons>
          <OverlayLangSelector>
              <a href={path}>{pathLabel}</a>
          </OverlayLangSelector>
        </div>
      }
      <FixedLeft>
        <Logo>
          <ButtonMobileDrawer 
            onClick={()=>setOpenState(!openState)}>
              { image() }
          </ButtonMobileDrawer>
        </Logo>
      </FixedLeft>
      <FixedRight>
        <MenuBarIcon onClick={()=> {
          setOverlayState(true)  
          showOverlay()
        }}>
          <FontAwesomeIcon icon={faBars}/>
        </MenuBarIcon>
      </FixedRight>
    </MenuBar>
  )
}

export default Mobile
