import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
//import { Menu } from "@mrmaru/lucilles-components/dist/index"
import Menu from "./menu/menu"
import Footer from "./footer"
import ArrowUp from "../images/arrow-up.png"
import ArrowDown from "../images/arrow-down.png"
import "./layout.css"

import Image from "../components/image-lucys-lemonade-white"

import { page_links, social_links } from "../data/assets"

const LocaleContext = React.createContext()

const Layout = ({ children, pageContext: { locale } }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <LocaleContext.Provider value={{ locale, page_links: page_links[locale] }}>
      <>
        <Helmet>
          <link href="https://fonts.googleapis.com/css?family=Oswald|Roboto:300|Lato:300|Raleway" rel="stylesheet"/>
        </Helmet>
        <Menu lang={locale} page_links={page_links[locale]} social_links={social_links} global_links={social_links} image={Image} />
        <img className="arrow-down" src={ArrowDown} alt="down arrow"/>
        <Header siteTitle={data.site.siteMetadata.title} />
        <main>{children}</main>
        <img className="arrow-up" src={ArrowUp} alt="up arrow" />
        <Footer />
      </>
    </LocaleContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export { Layout, LocaleContext }
