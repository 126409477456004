import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import Image from "./image-logo"

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `#0d1239`,
      marginBottom: `2rem`,
    }}
  >
    <div className="logo">
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          <Image />
        </Link>
      </h1>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
